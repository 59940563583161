
import JigTooltip from '@/components/input/JigTooltip.vue'
import GenericError from '@/components/snackbars/GenericError.vue'
import { CategoryState, LibraryCategories, LibraryCategory, LibraryCategoryStates } from '@/store/modules/teamlibrary/types'
import { Namespace } from '@/store/types'
import { ValidationRules } from '@/utils/input-validation'
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'

export function SortCategoriesByOrderAsc(a: LibraryCategory, b: LibraryCategory): number {
  if (a.CategoryOrder < b.CategoryOrder) {
    return -1
  } else if (a.CategoryOrder > b.CategoryOrder) {
    return 1
  } else {
    return 0
  }
}

@Component({
  components: {
    'generic-error': GenericError,
    'jig-tooltip': JigTooltip,
  },
})
export default class LibraryCategoriesVue extends Vue {
  @State('apiError', { namespace: Namespace.Utils })
  public apiError!: string
  @State('libraryCategories', { namespace: Namespace.TeamLibrary })
  public libraryCategories!: LibraryCategories
  @State('libraryCategoryStates', { namespace: Namespace.TeamLibrary })
  public libraryCategoryStates!: LibraryCategoryStates

  @Getter('isPreparingApp', { namespace: Namespace.Utils })
  public isPreparingApp!: boolean

  @Action('loadLibraryCategories', { namespace: Namespace.TeamLibrary })
  public loadLibraryCategories: any
  @Action('loadLibraryCategoryStates', { namespace: Namespace.TeamLibrary })
  public loadLibraryCategoryStates: any
  @Action('upsertLibraryCategories', { namespace: Namespace.TeamLibrary })
  public upsertLibraryCategories: any

  public $refs!: Vue['$refs'] & {
    genericError: GenericError
  }

  private readonly unknownCategoryIndex: number = -1
  private readonly invalidCategoryId: number = -1
  private editedCategory: LibraryCategory
  private editedIndex: number = this.unknownCategoryIndex
  private dataDirty: boolean = false
  private readonly hiddenStateId = 5
  private readonly TestCategoryId = 9999
  private readonly AnyCategoryId = 0
  private sortedCategories: LibraryCategory[] = []

  // New/Edit category dialog fields
  private dialogVisible: boolean = false
  private nameRules!: any
  private orderRules!: any
  private categoryStateRules!: any
  private dialogValid: boolean = false
  private libraryCategoryHeaders: any[] = [
    {
      text: 'Category ID',
      value: 'CategoryId',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Category Name',
      value: 'DisplayName',
      align: 'left',
      sortable: false,
    },
    {
      text: 'State',
      value: 'StateName',
      sortable: false,
      align: 'right',
    },
    {
      text: 'Display Order',
      value: 'CategoryOrder',
      sortable: false,
      align: 'right',
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false,
      align: 'right',
    },
  ]

  constructor() {
    super()
    this.nameRules = ValidationRules.RequiredName
    this.orderRules = [
      (v: any) => v !== null || 'Order is required',
      (v: any) => v !== '' || 'Order is required',
      (v: any) => !isNaN(v) || 'Order must be a number',
      (v: any) => (v >= 0 && v < 100000) || 'Order must be >= 0 and < 100000',
    ]
    this.categoryStateRules = ValidationRules.RequiredNumber
    this.editedCategory = Object.assign({}, this.$store.state.library.defaultLibraryCategory)
  }

  protected created() {
    this.loadLibraryCategories({}).then(this.loadLibraryCategoryStates({}))
  }

  private get formTitle() {
    if (this.editedIndex === this.unknownCategoryIndex) {
      this.editedCategory = Object.assign({}, this.$store.state.library.defaultLibraryCategory)
      return 'New Category'
    } else {
      return 'Edit Category'
    }
  }

  private get preSortedCategories(): any {
    if (this.libraryCategories != null) {
      this.sortedCategories = this.libraryCategories.Categories.slice()
      this.sortedCategories.sort(SortCategoriesByOrderAsc)
    }
    return this.sortedCategories
  }

  private canMoveUp(category: LibraryCategory, sortedCats: LibraryCategory[]): boolean {
    if (sortedCats != null && sortedCats.length > 0) {
      return sortedCats[0].CategoryId !== category.CategoryId
    }
    return false
  }

  private canMoveDown(category: LibraryCategory, sortedCats: LibraryCategory[]): boolean {
    if (sortedCats != null && sortedCats.length > 0) {
      return sortedCats[sortedCats.length - 1].CategoryId !== category.CategoryId
    }
    return false
  }

  private openEditCategoryDialog(category: any) {
    if (this.libraryCategories == null) {
      return
    }
    this.editedIndex = this.libraryCategories.Categories.indexOf(category)
    this.editedCategory = Object.assign({}, category)
    this.dialogVisible = true
  }

  // openNewCategoryDialog - Open the new category dialog
  private openNewCategoryDialog() {
    const catId = this.calculateNewCategoryId()

    if (catId === this.invalidCategoryId) {
      this.$refs.genericError.ShowError('Failed to generate a valid new Category Id')
      return
    }

    this.editedIndex = this.unknownCategoryIndex
    this.editedCategory = Object.assign({}, this.$store.state.library.defaultLibraryCategory)
    this.editedCategory.CategoryId = catId

    this.dialogVisible = true
  }

  private cancelDialog() {
    this.dialogVisible = false
    this.editedIndex = this.unknownCategoryIndex
  }

  private getStateNameByStateId(stateId: number): string {
    if (this.libraryCategoryStates == null) {
      return ''
    }

    const stateIndex = this.libraryCategoryStates.States.findIndex((x: CategoryState) => x.StateId === stateId)

    if (stateIndex === -1) {
      return ''
    }

    return this.libraryCategoryStates.States[stateIndex].StateName
  }

  private confirmDialog() {
    if (this.libraryCategories != null && this.libraryCategoryStates != null) {
      // Look up the state name so it displays correctly in the list as we don't get this from the dialog
      this.editedCategory.StateName = this.getStateNameByStateId(this.editedCategory.StateId)

      if (this.editedIndex === this.unknownCategoryIndex) {
        this.libraryCategories.Categories.push(Object.assign({}, this.editedCategory))
      } else {
        const existingIndex = this.libraryCategories.Categories.findIndex((x) => x.CategoryId === this.editedCategory.CategoryId)

        if (existingIndex === this.unknownCategoryIndex) {
          this.dialogVisible = false
          this.$refs.genericError.ShowError(`Category edit failed. Unable to update category
            data model, unknown cateogry index.`)
          this.editedIndex = this.unknownCategoryIndex
          return
        }

        this.libraryCategories.Categories[existingIndex] = Object.assign({}, this.editedCategory)
        this.resortDataTable()
      }

      this.editedIndex = this.unknownCategoryIndex
      this.recalculateCategoryOrderList()
      this.dataDirty = true
    }
    this.dialogVisible = false
  }

  private resortDataTable() {
    // Hack - Because our v-data-table is watching the categories array for changes and not the contents of the
    // items in the array, we need to trick the v-data-table into resorting.
    if (this.libraryCategories !== undefined) {
      this.libraryCategories.Categories.push({} as LibraryCategory)
      this.libraryCategories.Categories.pop()
    }
  }

  private recalculateCategoryOrderList() {
    if (this.libraryCategories == null) {
      return
    }

    // Exclude the first entry 'Any' CategoryID 0
    const categories = this.libraryCategories.Categories.slice(1)
    categories.sort(SortCategoriesByOrderAsc)

    const newOrder = []
    for (const c of categories) {
      if (c.StateId !== this.hiddenStateId) {
        newOrder.push(c.CategoryId)
      }
    }
    this.libraryCategories.CategoryOrder = newOrder
  }

  // calculateNewCategoryId scans the existing category ids and determines what the next
  // category id should be for the next new category entry
  private calculateNewCategoryId(): number {
    let newCategoryId = this.invalidCategoryId
    if (this.libraryCategories != null) {
      newCategoryId = 1
      for (const c of this.libraryCategories.Categories) {
        if (c.CategoryId >= newCategoryId && c.CategoryId !== this.TestCategoryId) {
          newCategoryId = c.CategoryId + 1
        }
      }
      if (newCategoryId === this.TestCategoryId) {
        newCategoryId++
      }
    }
    return newCategoryId
  }

  private moveCategory(category: LibraryCategory, direction: number) {
    if (this.libraryCategories == null) {
      return
    }

    // Get the categories in the same order as they're displayed in the table so we can work out neighbouring indicies.
    const clonedCategories = this.libraryCategories.Categories.slice()
    clonedCategories.sort(SortCategoriesByOrderAsc)
    const displayIndex = clonedCategories.findIndex((x: LibraryCategory) => x.CategoryId === category.CategoryId)
    const neighbourDisplayIndex = displayIndex + direction
    if (neighbourDisplayIndex < 0 || neighbourDisplayIndex >= clonedCategories.length) {
      return
    }
    const neighbourCategory = clonedCategories[neighbourDisplayIndex]

    // Determine our data indicies in the normal array
    const dataIndex = this.libraryCategories.Categories.indexOf(category)
    const neightbourDataIndex = this.libraryCategories.Categories.findIndex(
      (x: LibraryCategory) => x.CategoryId === neighbourCategory.CategoryId
    )

    // Swap the category orders around
    if (dataIndex !== this.unknownCategoryIndex && neightbourDataIndex !== this.unknownCategoryIndex) {
      const existingCategoryOrder = this.libraryCategories.Categories[dataIndex].CategoryOrder
      this.libraryCategories.Categories[dataIndex].CategoryOrder = neighbourCategory.CategoryOrder
      this.libraryCategories.Categories[neightbourDataIndex].CategoryOrder = existingCategoryOrder
      if (this.libraryCategories.Categories[dataIndex].CategoryOrder === existingCategoryOrder) {
        this.libraryCategories.Categories[dataIndex].CategoryOrder = neighbourCategory.CategoryOrder + direction
      }
      this.recalculateCategoryOrderList()
      this.dataDirty = true
      this.resortDataTable()
    }
  }

  private async saveChanges() {
    if (!this.dataDirty || this.libraryCategories == null) {
      return
    }

    if (await this.upsertLibraryCategories(this.libraryCategories.Categories)) {
      this.dataDirty = false
    }
  }

  private async resetData() {
    const p = this.loadLibraryCategories({}).then(this.loadLibraryCategoryStates({}))
    if (await p) {
      this.dataDirty = false
    }
  }
}
